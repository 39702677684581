<template>
  <div class="layout__container pb-6">
    <div class="layout__content">
      <h3 class="layout__title mb-6">
        {{ $t('overview') }}
        <span
          class="layout__title--subheading"
          data-cy="ccm-enabled-accounts-heading">
          {{ `${enabledAccounts} ${$t('cloudCostManagement.status.enabled')}` }}
        </span>
      </h3>
      <CyAlert
        theme="error"
        :content="accountsErrors"
        closeable
        @close="CLEAR_CE_ERRORS('accounts')"/>
      <CyCcmAccountsTable/>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import CyCcmAccountsTable from '@/components/CyCcmAccountsTable.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageCloudCostManagementAccounts',
  components: {
    CyCcmAccountsTable,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.cloudCostManagementAccounts'), [
      {
        name: 'cloudCostManagementSection',
        label: this.$t('routes.cloudCostManagement'),
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.cloudCostManagementTitle'),
      description: {
        text: this.$t('routes.cloudCostManagementSectionDescription'),
      },
    }
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      accounts: (state) => state.accounts,
      accountsErrors: (state) => state.errors.accounts,
    }),
    enabledAccounts () {
      return _.filter(this.accounts, 'enabled').length
    },
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'CLEAR_CE_ERRORS',
    ]),
  },
  i18n: {
    messages: {
      en: {
        overview: '@:routes.environmentOverview',
        title: '@:routes.cloudCostManagementAccounts',
      },
      es: {
        overview: '@:routes.environmentOverview',
        title: '@:routes.cloudCostManagementAccounts',
      },
      fr: {
        overview: '@:routes.environmentOverview',
        title: '@:routes.cloudCostManagementAccounts',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  &__container {
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__sidebar {
    padding-right: 18px;
    padding-left: 18px;
  }

  &__title--subheading {
    color: cy-get-color("primary", "light-3");
    font-size: $font-size-default;
  }
}
</style>
